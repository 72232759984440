<script lang="ts" setup>
const slots = useSlots();
const props = withDefaults(
  defineProps<{
    title?: string;
    content?: string;
    iconPath?: string;
    hexagonColor: string;
    collapseLabel?: string;
    expandLabel?: string;
    objectFit?: "contain" | "cover";
  }>(),
  {
    hexagonColor: "#aee1ff",
    objectFit: "contain",
  },
);
</script>

<template>
  <v-sheet
    rounded="lg"
    class="collapsable-card mb-4 mb-lg-18 px-6 py-6 px-lg-18 py-lg-12 background transparent-4"
  >
    <div class="collapsable-card__image-wrapper my-6 my-md-0">
      <v-sheet
        :color="props.hexagonColor"
        class="hw-hexagon collapsable-card__image mx-auto"
      >

        <v-responsive
          :aspect-ratio="248 / 280"
          content-class="d-flex justify-center"
        >
          <v-img
            v-if="props.iconPath"
            :src="props.iconPath"
            :style="{maxWidth: props.objectFit === 'contain' ? '60%' : 'none'}"
            :object-fit="props.objectFit"
          />
        </v-responsive>
      </v-sheet>
    </div>

    <div class="collapsable-card__title text-center text-md-left">
      <slot name="title">
        <h2 class="text-h2 pt-0">
          {{ props.title }}
        </h2>
      </slot>
    </div>

    <div class="collapsable-card__content text-center text-md-left">
      <slot name="content">
        <p>{{ props.content }}</p>
      </slot>
    </div>

    <ExpansionPanel
      v-if="slots.default"
      position="left"
      :title="props.expandLabel"
      :title-collapsed="props.collapseLabel"
      class="collapsable-card__collapsable-content mt-8"
    >
      <template #header></template>
      <template #default>
        <slot></slot>
      </template>
    </ExpansionPanel>
  </v-sheet>
</template>

<style lang="scss" scoped>
@use "assets/scss/settings";
@use "~/../_common/assets/scss/grid";

.collapsable-card {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 72px;
  grid-template-areas:
    "title"
    "image"
    "content"
    "subtitle"
    "buttons"
    "collapsable-content";

  @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
    grid-template-columns: 248px 1fr;
    grid-template-areas:
      "image title"
      "image content"
      "image subtitle"
      "collapsable-content collapsable-content";
  }

  &__image-wrapper {
    grid-area: image;
    align-self: center;
  }

  &__image {
    height: 100%;
    max-height: 280px;
    width: 100%;
    max-width: 248px;
  }

  &__title {
    grid-area: title;
  }

  &__content {
    grid-area: content;
  }

  &__collapsable-content {
    grid-area: collapsable-content;
  }
}
</style>
